import { Overlay } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import {
  MAT_SELECT_SCROLL_STRATEGY,
  MatSelectModule,
} from '@angular/material/select';

@NgModule({
  imports: [MatSelectModule],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
  exports: [MatSelectModule],
})
export class CustomSelectModule {}
